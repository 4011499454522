.App {
    width: 100%;
}

header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

header .left {
    width: 50%;
}

header .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 50%;
    padding: 0.6em;
}

a {
    color: var(--green);
    text-decoration: none;
}

button {
    background-color: var(--green);
    border: 1px solid var(--cream);
    border-radius: 1px;
    font-family: 'SupplyMono', monospace;
    color: var(--black);
    font-size: 1.4em;
    padding: 0.25em 0.3em 0.05em 0.3em;
}

.row {
    display: flex;
    flex-direction: row;
}

.wrap {
    flex-wrap: wrap;
}

.row .col {
    display: flex;
    flex-direction: column;
}

.imgWrapper {
    margin: 4px;
    padding: 0px;
    width: 176px;
    height: 150px;
    position: relative;
}

.imgWrapper img {
    width: 176px;
    height: 150px;
    border-radius: 1px;
}

.imgWrapper .tokenId {
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: var(--cream);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 1.2em;
}

.Drifter .DrifterImage img {
    width: 400px;
    height: auto;
}

.Attribute {
    margin: 3px 12px;
    font-size: 1.2em;
}

.Attribute .Trait {
    margin-right: 9px;
    color: var(--cream);
    font-size: 0.9em;
}

.Attribute .Value {
    font-size: 1.1em;
    color: var(--green);
}

.Drifter .links {
    display: flex;
    flex-direction: row;
}
.Drifter .links a {
    background-color: var(--green);
    color: var(--black);
    font-size: 1.6em;
    padding: 8px 6px 4px 6px;
    margin: 6px;
    text-decoration: none;
    border-bottom: 2px solid var(--black);
}

.AttributeFilter {
    margin: 8px;
}

.AttributeFilter input {
    width: 100%;
    font-size: 2em;
    background-color: var(--cream);
    color: var(--black);
    outline: none;
    border: none;
    border-bottom: 4px solid var(--green);
    text-align: center;
}
